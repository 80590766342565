
.Timeline {
  height: 60vh;
  min-height: 350px;
  /* width: 100%; */
  /* min-width: 500px; */
  /* width: calc(100% + 1em); */
  /* margin-bottom: 2em; */
}

